import { useMutation } from '@tanstack/react-query';

import useSupabase from '../../supabase';

export function useSetReferredMutation() {
  const client = useSupabase();

  const mutationFn = async (refCode: string) => {
    const response = await client.rpc('insert_referral', { ref_code: refCode });
    return response;
  };

  return useMutation({ mutationFn });
}
