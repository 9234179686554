import { useEffect, useState } from 'react';
import { formatUnits } from 'viem';

import { Box, Divider, Typography } from '@mui/material';

import { useCreatorByUsernameQuery } from '../../api/users/useCreatorByUsernameQuery';
import { CheckEmptyCircle } from '../../components/icons';
import { ChevronRight } from '../../components/icons/chevronRight';
import { useMainTokenInfo } from '../../hooks/useMainTokenInfo';
import { SubscriptionPlanType } from '../../types/SubscriptionPlanType';
import { useAllSubPricesReadMethod } from '../../web3/hooks/read/useGetSubPricesReadMethod';

export function SelectionPlanSidePanel({
  planSelected,
  handlePlanChange,
  username,
}: {
  planSelected: SubscriptionPlanType;
  handlePlanChange: () => void;
  username: string;
}) {
  const [selectedPriceInWei, setSelectedPriceInWei] = useState<
    bigint | undefined
  >(undefined);
  const { data: creator } = useCreatorByUsernameQuery(username);

  const { data: subPrices, isSuccess } = useAllSubPricesReadMethod(
    creator?.wallet_address,
  );

  const { symbol } = useMainTokenInfo();

  useEffect(() => {
    if (subPrices && isSuccess) {
      const subPrice =
        planSelected === SubscriptionPlanType.LIFETIME
          ? subPrices[1]
          : subPrices[0];

      setSelectedPriceInWei(subPrice.result);
    }
  }, [subPrices, isSuccess, planSelected]);

  const selectedPriceInEthStr = selectedPriceInWei
    ? formatUnits(selectedPriceInWei, 18)
    : 'N/A';

  return (
    <Box display="flex" flexDirection="column" gap={10}>
      <Typography variant="h3" fontWeight={500}>
        Your selected plan
      </Typography>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          border: '2px solid #2D2F33',
          borderRadius: 3,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: 6,
          }}
        >
          <Typography>{planSelected}</Typography>

          <Box display="flex" gap={1} paddingTop={3}>
            <Typography variant="h2">
              {selectedPriceInEthStr} {symbol}
            </Typography>

            {planSelected === SubscriptionPlanType.MONTHLY && (
              <Typography variant="body1">/month</Typography>
            )}
          </Box>

          <Typography
            paddingTop={1}
            maxWidth="229px"
            variant="body2"
            color="#ACAFB3"
          >
            Monthly community and staking access
          </Typography>

          <Box
            sx={{
              display: 'flex',
              gap: 2,
              alignItems: 'center',
              cursor: 'pointer',
              paddingTop: 6,
            }}
            onClick={handlePlanChange}
          >
            <Typography variant="body1" fontWeight={600}>
              Switch plan
            </Typography>

            <ChevronRight size={16} />
          </Box>
        </Box>

        <Divider />

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
            padding: 6,
          }}
        >
          <Typography variant="body2" color="#ACAFB3">
            What`s included
          </Typography>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Box display="flex" gap={2} alignItems="center">
              <CheckEmptyCircle />

              <Typography variant="body2" color="#E3E4E6">
                Private Community Access
              </Typography>
            </Box>

            <Box display="flex" gap={2} alignItems="center">
              <CheckEmptyCircle />

              <Typography variant="body2" color="#E3E4E6">
                Community Staking Pool Access
              </Typography>
            </Box>

            <Box display="flex" gap={2} alignItems="center">
              <CheckEmptyCircle />

              <Typography
                variant="body2"
                sx={{ display: 'flex', gap: 1, color: '#E3E4E6' }}
              >
                <Typography fontWeight={700} variant="body2">
                  BASIC
                </Typography>
                Point Rewards
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
